import styled from "styled-components"

const SideInfoBox = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  position: sticky;
  top: 0px;
  margin-bottom: 20px;

  p {
    font-size: 14px;
    line-height: 22px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: #63b3ed;
    &:hover {
      color: #4a9ad4;
    }
  }

  .contact-box-list li {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }

    b {
      margin-right: 5px;
    }

    svg {
      display: block;
    }
  }
`

export { SideInfoBox }
