import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Icon from "../utilities/icons"
import { SideInfoBox } from "../components/styles/SideInfoBox"
import SEO from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title="About Beauty Gems | Mobile Beautician Newark"
        description="About Beauty Gems, a mobile beauty therapist, offering a wide range of treatments at great prices in Newark, Claypole, Fernwood, Winthorpe, Coddington, Collingham, Elston, Muskham and other surrounding areas."
      />
      <div class="standard-page-container">
        <div className="inner-container">
          <h1>About</h1>
          <div className="two-column-layout-wrapper">
            <div className="two-column-layout-item_wide">
              <p>
                Hello, my name is Gemma, and I've been a mobile beautician in
                the Newark-on-Trent area for over{" "}
                {new Date().getFullYear() - 2009} years.
              </p>
              <p>
                I am a fully qualified beauty therapist. I have extensive
                experience and knowledge of beauty therapy, and have gained NVQ
                Level 2 and 3 and other additional beautician qualifications
                too.
              </p>
              <p>
                The beauty of using a mobile beautician is you get all the
                benefits of a beauty salon, brought to you. In this day and age
                people have little time to go to the trouble of visiting a
                beauty salon, that's why I became a mobile beautician, to make
                beauty treatments accessible to all.
              </p>
              <p>
                I do treatments in a wide area surrounding Newark, including
                Balderton, Claypole, Farndon, Muskham, Elston, Collingham,
                Winthorpe, Coddington and many other surrounding villages. If
                your town or village isn't listed, get in touch, and I'm sure we
                can work something out.
              </p>
              <p>
                I offer a wide range of treatments at competetive prices. My aim
                is make you feel totally relaxed and comfortable in your own
                home and to make you feel pampered, all without the stress of
                going to a salon.
              </p>
              <p>
                The <Link to="/treatments">treatments</Link> I offer are on the
                treatments page, please do not hesitate to contact me if you
                would like to know more about a treatment, for example what is
                involved and what products are used. If you would like to book a
                treatment you can use the contact form or phone or text me on
                07796 252618.
              </p>
            </div>
            <div className="two-column-layout-item_narrow">
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Vouchers Available</h3>
                <p>
                  You can purchase vouchers from me, which are great for that
                  special occasion; birthdays, Christmas and anniversaries. A
                  simple but thoughtful and effective gift for that special
                  person in your life.
                </p>
              </SideInfoBox>
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Treatments &amp; Prices</h3>
                <p>
                  On the <Link to="/treatments">treatments</Link> page, you can
                  see all the treatments available and their prices. Each
                  treatment has a brief description of what exactly is involved,
                  including the methods and products used.
                </p>
              </SideInfoBox>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
